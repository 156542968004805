import React, { useState, useRef, useEffect } from 'react';
import { CgChevronUpO, CgChevronDownO } from 'react-icons/cg';

// TRS Renderer
export const TRSRenderer = ({ value, data, expandedRows, toggleRowExpansion, node, showConfirmationBox }) => {
    const values = value ? value.split("|") : [];
    const firstValue = values[0] || "";
    const hasMoreContent = firstValue.length > 10 || values.length > 1;
    const expanded = expandedRows[node.id];
    const displayedContent = expanded ? values : [firstValue.slice(0, data?.is_trs ? 10 : firstValue.length === 10 ? 10 : 9)];
  
    const handleToggle = () => toggleRowExpansion(node.id);
  
    const createHyperlink = (text) => {
      return data?.is_trs ? (
        <button
          className="link-button"
          onClick={() => showConfirmationBox(text)}
          style={{
            color: "#009EF7",
            background: "none",
            border: "none",
            cursor: "pointer",
            padding: 0,
          }}
        >
          {text}
        </button>
      ) : (
        text
      );
    };
  
    return (
      <div
        style={{
          whiteSpace: expanded ? "normal" : "nowrap",
          display: expanded ? "block" : "flex",
          alignItems: expanded ? "flex-start" : "center",
          lineHeight: expanded ? "1.5" : "",
          wordBreak: "break-word",
          overflowWrap: "break-word",
        }}
      >
        {displayedContent.map((item, index) => (
          <div key={index} style={{ marginBottom: expanded ? "5px" : "0" }}>
            <span>{createHyperlink(item)}</span>
          </div>
        ))}
        {hasMoreContent && (
          <button
            onClick={handleToggle} // Toggle the expansion on button click
            style={{
              color: "blue",
              background: "none",
              border: "none",
              cursor: "pointer",
              marginTop: expanded ? "5px" : "0",
            }}
          >
            {expanded ? (
              <CgChevronUpO className="btn-icon" />
            ) : (
              <CgChevronDownO className="btn-icon" />
            )}
          </button>
        )}
      </div>
    );
  };
  

// Formation Name Renderer
export const GrGeRenderer = ({ value, expandedRows, toggleRowExpansion, node }) => {
    const [columnWidth, setColumnWidth] = useState(0);
    const containerRef = useRef(null);
  
    const values = value ? value.split("|") : [];
    const firstValue = values[0] || "";
  
    const calculateMaxCharacters = (width) => Math.floor(width / 10);
    const maxCharacters = calculateMaxCharacters(columnWidth);
  
    // Use the expanded state from the shared expandedRows object
    const expanded = expandedRows[node.id];
  
    const displayedContent = expanded
      ? values.length > 1
        ? values
        : [firstValue]
      : [firstValue.slice(0, maxCharacters)];
  
    const hasMoreContent = firstValue.length > maxCharacters || values.length > 1;
  
    useEffect(() => {
      const container = containerRef.current;
      if (!container) return;
  
      const observer = new ResizeObserver((entries) => {
        for (let entry of entries) {
          setColumnWidth(entry.contentRect.width);
        }
      });
  
      observer.observe(container);
  
      return () => {
        observer.disconnect();
      };
    }, []);
  
    return (
      <div
        ref={containerRef}
        style={{
          whiteSpace: expanded ? "normal" : "nowrap",
          display: expanded ? "block" : "flex",
          alignItems: expanded ? "flex-start" : "center",
          lineHeight: expanded ? "1.5" : "",
          wordBreak: "break-word",
          overflowWrap: "break-word",
        }}
      >
        {displayedContent.map((item, index) => (
          <div key={index} style={{ marginBottom: expanded ? "5px" : "0" }}>
            {item}
          </div>
        ))}
        {hasMoreContent && (
          <button
            onClick={() => toggleRowExpansion(node.id)} // Toggle the expansion on button click
            style={{
              color: "blue",
              background: "none",
              border: "none",
              cursor: "pointer",
              marginTop: expanded ? "5px" : "0",
            }}
          >
            {expanded ? (
              <CgChevronUpO className="btn-icon" />
            ) : (
              <CgChevronDownO className="btn-icon" />
            )}
          </button>
        )}
      </div>
    );
  };
