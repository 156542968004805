import { handleActions } from "redux-actions";

const defaultState = {
  __persist: true,
  username: null,
  loading: false,
  accessToken: null,
  refreshToken: null,
  firstname: null,
  lastname: null,
  company: null,
  address: null,
  isAdmin: false,
  phone: null,
  error: null,
  theme: localStorage.getItem('theme') ?? 'light',
  ak: null,
  filterTrigger: 0,
  updateSuccess: false,
  updating: false,
};

export default handleActions(
  {
    SIGNUP_REQUEST: (state) => {
      return {
        ...state,
        loading: true,
        error: null,
      };
    },

    SIGNUP_SUCCESS: (state) => {
      return {
        ...state,
        loading: false,
        error: null,
      };
    },

    SIGNUP_FAILURE: (state, { payload: { error } }) => {
      return {
        ...state,
        loading: false,
        error,
      };
    },

    LOGIN_USER_REQUEST: (state) => {
      return {
        ...state,
        loading: true,
        error: null,
      };
    },

    LOGIN_USER_SUCCESS: (state, { payload: { accessToken, refreshToken } }) => {
      return {
        ...state,
        loading: false,
        accessToken,
        refreshToken,
        error: null,
      };
    },

    LOGIN_USER_FAILURE: (state, { payload: { error } }) => {
      return {
        ...state,
        loading: false,
        accessToken: null,
        refreshToken: null,
        error,
      };
    },

    PROFILE_REQUEST: (state) => ({
      ...state,
      loading: true,
      error: null,
    }),

    PROFILE_SUCCESS: (
      state,
      { payload: { username, fn, ln, co, ad, ph, iau, ak } }
    ) => ({
      ...state,
      username,
      firstname: fn,
      lastname: ln,
      company: co,
      address: ad,
      phone: ph,
      ak: ak,
      isAdmin: iau,
      loading: false,
      error: null,
    }),

    PROFILE_FAILURE: (state, { payload: { error } }) => ({
      ...state,
      loading: false,
      error,
    }),

    CLEAR_ALL_DATA: () => {
      localStorage.removeItem('clickedIds');
      localStorage.removeItem('columnWidths');
      localStorage.removeItem('clickedRowNodeIds');
      return {
        ...defaultState,
        theme: localStorage.getItem('theme') ?? 'light',
      };
    },

    REFRESH_TOKEN_SUCCESS: (state, { payload: { accessToken } }) => ({
      ...state,
      accessToken,
    }),

    CHANGE_THEME: (state, { payload: { mode } }) => {
      return {
        ...state,
        theme: mode,
      }
    },

    TRIGGER_LOCAL_GRID_FILTER: (state) => {
      const triggerValue = state.filterTrigger + 1;
      return {
        ...state,
        filterTrigger: triggerValue
      }
    },

    UPDATE_USER_INFO_REQUEST: (state) => {
			return {
				...state,
				error: null,
				updating: true,
				updateSuccess: false,
			};
		},
		UPDATE_USER_INFO_FAILURE: (state, { payload: err }) => {
			return {
				...state,
				error: err.message,
				updating: false,
				updateSuccess: false,
			};
		},
		UPDATE_USER_INFO_SUCCESS: (state, action) => {
      return {
        ...state,
        firstname: action.payload.fn,
        lastname: action.payload.ln,
        company: action.payload.co,
        address: action.payload.ad,
        phone: action.payload.ph,
        updating: false,
        error: null,
        updateSuccess: true,
      };
		},
  },
  defaultState
);
