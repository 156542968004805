import { useEffect, useState } from "react";
import { connect } from "react-redux";
// import SelectView from "./SelectView";
import SelectViewNew from "./SelectViewNew";
import TRSMAdvanced from "./TRSMAdvanced";
// import moment from "moment";
import TagsInput from "react-tagsinput";
import Modal from "../../components/common/modal";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronLeft,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";
import { parseTRSMString, stringifyTRSM } from "../../utils";

import {
  getInstrumentTypes,
  setTRSM,
  setTags,
  udpateFilter,
  fetchPreviousFilters,
  setFilterValues,
  // addFilterInPrevious,
  setFirstLoad,
} from "../../actions/advancedSearchAction";

const SearchFilter = ({
  county,
  getInstrumentTypes,
  instrumentTypes,
  getFilteredData,
  trsm,
  setTRSM,
  setTags,
  filterData,
  udpateFilter,
  defaultFilters,
  // tags,
  loading,
  fetchPreviousFilters,
  setFilterValues,
  // addFilterInPrevious,
  abortController,
  setFirstLoad,
  isFirstLoad,
  theme,
}) => {
  const minWidth = 230;
  // const [controller, setController] = useState();
  const maxPrevFilterCounter = 6;
  const [showFilter, setShowFilter] = useState(true);
  const [showAdvancedTrs, setShowAdvancedTrs] = useState(false);
  const [show, setShow] = useState(false);
  const [modalMessage, setModalMessage] = useState('');
  const [prevFilterCounter, setPrevFilterCounter] = useState(1);
  const [inputWidth, setInputWidth] = useState(minWidth);
  const [inputValue, setInputValue] = useState("");

  const inputsHandler = (e) => {
    udpateFilter({ ...filterData, [e.target.name]: e.target.value });
  };

  const handleGGSeperate = (e) => {
    udpateFilter({ ...filterData, [e.target.name]: e.target.checked });
  };

  const countyHandler = (e) => {
    udpateFilter({
      ...filterData,
      county: [...e],
    });
  };

  const instrumentTypeHandler = (e) => {
    udpateFilter({
      ...filterData,
      instrument_type: [...e],
    });
  };

  const toggleFilter = () => {
    setShowFilter(!showFilter);
  };

  
  useEffect(() => {
    if(isFirstLoad) {
      getInstrumentTypes();
      fetchPreviousFilters();
      getFilteredData();
      updateDateAfterInitialSearch();
      setFirstLoad(false);
    }
    // eslint-disable-next-line
  }, [getInstrumentTypes, fetchPreviousFilters, isFirstLoad, getFilteredData]);

  const updateDateAfterInitialSearch = () => {
    udpateFilter({
      ...filterData,
      from_date: "",
      to_date: "",
    });
  }

  const validateFilters = () => {
    let hasError = false;
    if (
      filterData.from_date === "" &&
      filterData.to_date === "" &&
      filterData.book === "" &&
      filterData.page === "" &&
      filterData.grantees.length === 0 &&
      filterData.grantors.length === 0 &&
      filterData.ggTogether.length === 0 &&
      trsm.length === 0
    ) {
      hasError = true;
      setModalMessage(
        "Searching all dates requires one of the following. Name, TRS, County + Book/Page"
      );
    } else if (
      filterData.county.length === 0 &&
      (filterData.book !== "" || filterData.page !== "")
    ) {
      setModalMessage("County is required when searching by book/page");
      hasError = true;
    }
    setShow(hasError);
    return hasError;
  };
  
  const applyFilters = () => {
    if(!validateFilters()) {
      setTags({...filterData, trs: trsm});
      getFilteredData();
      setPrevFilterCounter(1);
      toggleFilter();
    }
  };

  const clearFilterData = () => {
    setTRSM([]);
    setPrevFilterCounter(0);
    udpateFilter({
      ...defaultFilters,
      from_date: "",
      to_date: "",
    });
  };

  const trsmChangeHandler = (e) => {
    if (e.length) {
      const last = e.pop();
      if (last && last.trim() !== "") {
        const modified = stringifyTRSM(parseTRSMString(last.toUpperCase()));
        const trsPattern = /^\d{2}[snSN]-\d{2}[ewEW]-\d{2}$/;
        if(trsPattern.test(modified)) {
          e.push(modified);
        } else {
          setModalMessage("Please enter a valid TRS");
          setShow(true);
        }
      }
    }
    setTRSM(e);
  };

  const inputChangeHandler = (value) => {
    if(value.length > 20) {
      setInputWidth(minWidth + ((value.length - 20) * 6));
    } else {
      setInputWidth(minWidth);
    }
    setInputValue(value);
  }

  const nameChangeHandler = (e, type = 'name') => {
    if (e.length) {
      const last = e.pop();
      if (last && last.trim() !== "") {
        e.push(last);
      }
    }
    let key = 'ggTogether';
    if(type === 'grantees') {
      key = 'grantees'
    } else if(type === 'grantors') {
      key = 'grantors'
    }
    udpateFilter({
      ...filterData,
      [key]: e,
    });
  };

  const fetchPreviousFilterValues = () => {
    setPrevFilterCounter(prevFilterCounter + 1);
    setFilterValues(prevFilterCounter);
  }

  return (
    <div
      className={`search-filter search-filter-${theme}`}
      style={{ left: showFilter ? "0px" : "-820px" }}
    >
      <div
        className="filters-topheader"
        style={{ left: showFilter ? "820px" : "0px" }}
      >
        <div className="settingbtn" onClick={toggleFilter}>
          {showFilter ? (
            <FontAwesomeIcon icon={faChevronLeft} />
          ) : (
            <FontAwesomeIcon icon={faChevronRight} />
          )}
        </div>
      </div>
      <div className="d-flex">
        {filterData.isGGSeparate && (
          <>
            <div className="grantees">
              <div className="header">
                <label>Grantor</label>
              </div>
              {/* <div>
                <input
                  placeholder="Grantor"
                  className="form-control bg-transparent"
                  type="text"
                  name="grantors"
                  autoComplete="off"
                  value={filterData.grantors}
                  onChange={inputsHandler}
                />
              </div> */}
              <div
                style={{
                  marginTop: "15px",
                  overflow: "auto",
                  maxHeight: "39px",
                }}
              >
                <TagsInput
                  value={filterData.grantors}
                  onChange={(e) => nameChangeHandler(e, "grantors")}
                  addKeys={[188, 13]}
                  addOnBlur
                  inputProps={{
                    placeholder: filterData.grantors.length ? "" : "Grantor",
                  }}
                />
              </div>
            </div>
            <div className="grantees">
              <div className="header">
                <label>Grantee</label>
              </div>
              {/* <div>
                <input
                  placeholder="Grantee"
                  name="grantees"
                  className="form-control bg-transparent"
                  type="text"
                  autoComplete="off"
                  value={filterData.grantees}
                  onChange={inputsHandler}
                />
              </div> */}
              <div
                style={{
                  marginTop: "15px",
                  overflow: "auto",
                  maxHeight: "39px",
                }}
              >
                <TagsInput
                  value={filterData.grantees}
                  onChange={(e) => nameChangeHandler(e, "grantees")}
                  addKeys={[188, 13]}
                  addOnBlur
                  inputProps={{
                    placeholder: filterData.grantees.length ? "" : "Grantee",
                  }}
                />
              </div>
            </div>
          </>
        )}
        {!filterData.isGGSeparate && (
          <div className="ggTogether">
            <div className="header">
              <label>Name</label>
            </div>
            <div
              className="tagsinput"
              style={{ marginTop: "15px", overflow: "auto", maxHeight: "70px" }}
            >
              <TagsInput
                value={filterData.ggTogether}
                onChange={nameChangeHandler}
                addKeys={[188, 13]}
                addOnBlur
                inputValue={inputValue}
                onChangeInput={inputChangeHandler}
                inputProps={{
                  style: {
                    width: `${inputWidth}px`,
                    maxWidth: '750px',
                    // backgroundColor: 'red'
                  },
                  placeholder: "",
                  // placeholder: filterData.ggTogether.length
                  //   ? ""
                  //   : "Last First (No Comma)",
                  className: "react-tagsinput-input",
                }}
              />
            </div>
          </div>
        )}
      </div>
      <div className="d-flex" style={{ zIndex: 10 }}>
        <div className="check-box separateGG">
          <input
            className="check-pointer"
            type="checkbox"
            name="isGGSeparate"
            checked={filterData.isGGSeparate}
            onChange={handleGGSeperate}
          />
          <label>Search by Grantor/Grantee</label>
        </div>
      </div>
      <div className="d-flex">
        <div className="dates">
          <div className="header">
            <label>Recorded Date</label>
          </div>
          <div className="d-flex">
            <div>
              <label>From</label>
              <input
                type="date"
                name="from_date"
                // disabled={filterData.isDateFiltered}
                value={filterData.from_date}
                onChange={inputsHandler}
                className="form-control bg-transparent date-filter"
              />
            </div>
            <div className="ml-2">
              <label>To</label>
              <input
                type="date"
                name="to_date"
                // disabled={filterData.isDateFiltered}
                value={filterData.to_date}
                onChange={inputsHandler}
                className="form-control bg-transparent date-filter"
              />
            </div>
          </div>
        </div>
        <div className="county">
          <div className="header">
            <label>County</label>
          </div>
          <div>
            <SelectViewNew
              className="filter"
              options={county.map((item) => ({ value: item, label: item }))}
              values={filterData.county.map((item) => ({
                value: item,
                label: item,
              }))}
              placeholder="County"
              multiple
              searchable
              onChange={countyHandler}
              width={300}
              theme={theme}
            />
          </div>
        </div>
      </div>
      <div className="d-flex date-search" style={{ zIndex: 10 }}>
        <div
          style={{ marginLeft: "25px", marginTop: "7px", color: theme === 'dark' ? '#ffffff' : '#756a6a' }}
        >
          Leave blank to search all dates
        </div>
      </div>
      <div className="d-flex" style={{ marginTop: "-10px" }}>
        <div className="instrument-type">
          <div className="header">
            <label>Instrument Type</label>
          </div>
          <div>
            <SelectViewNew
              className="filter"
              options={instrumentTypes.map((item) => ({
                value: item,
                label: item,
              }))}
              values={filterData.instrument_type.map((item) => ({
                value: item,
                label: item,
              }))}
              placeholder="Instrument Type"
              multiple
              searchable
              onChange={instrumentTypeHandler}
              width={300}
              theme={theme}
            />
          </div>
        </div>
        <div className="input-fields">
          <div className="header">
            <label>Book</label>
          </div>
          <div>
            <input
              placeholder="Book"
              className="form-control bg-transparent"
              type="text"
              name="book"
              autoComplete="off"
              value={filterData.book}
              onChange={inputsHandler}
            />
          </div>
        </div>
        <div className="input-fields">
          <div className="header">
            <label>Page</label>
          </div>
          <div>
            <input
              placeholder="Page"
              name="page"
              className="form-control bg-transparent"
              type="text"
              autoComplete="off"
              value={filterData.page}
              onChange={inputsHandler}
            />
          </div>
        </div>
      </div>
      <div className="d-flex">
        <div className="trsm">
          <div className="header">
            <label>TRS</label>
          </div>
          <div
            style={{
              marginTop: "15px",
              maxWidth: "785px",
              maxHeight: "50px",
              overflow: "auto",
            }}
          >
            <TagsInput
              value={trsm}
              onChange={trsmChangeHandler}
              addKeys={[188, 13]}
              addOnBlur
              inputProps={{ placeholder: trsm.length ? "" : "01N-01W-01" }}
            />
          </div>
        </div>
      </div>
      <div className="d-flex">
        <div className="buttons">
          {loading ? (
            <button
              className="btn btn-primary right"
              onClick={abortController}
            >
              Cancel
            </button>
          ) : (
            <button
              className="btn btn-primary right"
              onClick={applyFilters}
            >
              Apply
            </button>
          )}
          <button className="btn btn-info left" onClick={clearFilterData}>
            Clear All
          </button>
          <button
            className="btn btn-primary"
            style={{ position: "absolute", left: "105px" }}
            disabled={loading || maxPrevFilterCounter === prevFilterCounter}
            onClick={fetchPreviousFilterValues}
          >
            Previous Search
          </button>
          {/* <button
            className="btn btn-primary right"
            disabled={loading}
            onClick={applyFilters}
          >
            Apply
          </button> */}
        </div>
      </div>
      {showAdvancedTrs && (
        <TRSMAdvanced onClose={() => setShowAdvancedTrs(false)} />
      )}
      <Modal
        showModal={show}
        header={""}
        html={modalMessage}
        theme={theme}
        footerHtml={
          <div>
            <button className="btn btn-info" onClick={() => setShow(false)}>
              Close
            </button>
          </div>
        }
        onHide={() => setShow(false)}
      />
    </div>
  );
};

const mapStateToProps = (state) => ({
  theme: state.user.theme,
  instrumentTypes: state.advancedSearch.instrumentTypes,
  county: state.advancedSearch.county,
  trsm: state.advancedSearch.trsm,
  filterData: state.advancedSearch.filterData,
  defaultFilters: state.advancedSearch.defaultFilters,
  tags: state.advancedSearch.tags,
  isFirstLoad: state.advancedSearch.isFirstLoad,
  loading: state.advancedSearch.loading,
});

const mapDispatchToProps = (dispatch) => ({
  getInstrumentTypes: () => dispatch(getInstrumentTypes()),
  setTRSM: (trsm) => dispatch(setTRSM(trsm)),
  setTags: (filters) => dispatch(setTags(filters)),
  udpateFilter: (filters) => dispatch(udpateFilter(filters)),
  fetchPreviousFilters: () => dispatch(fetchPreviousFilters()),
  // addFilterInPrevious: (filters) => dispatch(addFilterInPrevious(filters)),
  setFilterValues: (index) => dispatch(setFilterValues(index)),
  setFirstLoad: (value) => dispatch(setFirstLoad(value)),
});

const SearchFilterContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(SearchFilter);

export default SearchFilterContainer;
